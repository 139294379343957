import { mergeObjects } from '../app/common/utils';
import StandardForm from './form.standard';
import { FormConfig } from '../typings/types';

const customizations: FormConfig = {
  ...StandardForm,
  texts: {
    ...StandardForm.texts,
    introTitle: 'Formål & Omfang',
    introText: [
      'For at kunne overholde hvidvasklovgivningen, er det nødvendigt, at vi modtager og opbevarer information om forskellige økonomiske og personlige forhold fra alle kunder. Den information vi har brug for, fremgår af dette spørgeskema.',
      'Dine svar indhentes alene i forhold til hvidvaskloven og oplysningerne påvirker ikke prisen på dine ydelser. Oplysningerne indhentes og slettes jf. gældende regler om hvidvask og persondata.',
      'Spørgeskemaet er inddelt i 5 sider: Kundeforhold, indtægt og formue, kontanter, udland og PEP (Politisk eksponeret person).',
      'Du skal logge ind med MitID for at starte spørgeskemaet. Besvarelsen af spørgsmål på de næste sider foregår i et sikkert miljø. Du er til enhver tid velkommen til at kontakte din rådgiver, hvis du er i tvivl ved et spørgsmål.',
      'Når du starter spørgeskemaet, accepterer du samtidig, at denne side gør brug af cookies ifm. indsamling af dine indtastede oplysninger.'
    ]
  },
  form: {
    ...StandardForm.form,
    questions: {
      ...StandardForm.form.questions,
      customerEngagementOtherBanks: {
        section: 'engagement',
        type: 'radiobuttons',
        label: 'Har du konti i andre pengeinstitutter?',
        required: true,
        options: [
          {
            value: 'partial',
            label: 'Ja'
          },
          {
            value: 'full',
            label: 'Nej'
          }
        ]
      },

      foreignAssociated: {
        section: 'abroad',
        type: 'radiobuttons',
        label:
          'Har du økonomisk tilknytning til andre lande end Danmark? F.eks. arbejde, bolig, familie mv.?',
        required: true,
        options: [
          {
            value: 'Yes',
            label: 'Ja'
          },
          {
            value: 'No',
            label: 'Nej'
          }
        ]
      }
    }
  }
};

export default mergeObjects(StandardForm, customizations);
