import { BankConfig } from '../typings/types';

const config: BankConfig = {
  name: 'Sydbank',
  bankId: 1,
  logoWidth: 140,
  color: '#017ac9',
  googleAnalytics: {
    test: 'UA-51775016-6',
    production: 'UA-51775016-1'
  },
  forceOverride: false,
  backgroundGradient: ['#002d59', '#007ec7'],
  backgroundImage: '1_bg.png',
  topBar: { background: '#fff', color: '#002d59' },
  body: { headlineColor: '#fff', bodyColor: '#fff' },
  reaktionId: 10941
};

export default config;
